.h-has-min-width {
  min-width: 8.125rem;
}

.input,
.textarea {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}
