.content {
  text-align: justify;
  
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;

  h1 {
    font-size: $h-h1-font-size;
  }

  h2 {
    font-size: $h-h2-font-size;
  }

  h3 {
    font-size: $h-h3-font-size;
  }

  h4 {
    font-size: $h-h4-font-size;
  }

  h5 {
    font-size: $h-h5-font-size;
  }

  h6 {
    font-size: $h-h6-font-size;
  }

  a {
    &:hover {
      text-decoration: underline;
    }

    &.button {
      &:hover {
        text-decoration: none;
      }
    }
  }

  ol,
  ul {
    margin-left: 1rem;
  }

  kbd {
    font-family: $family-code;
    font-size: $code-size;
    color: $red-invert;
    background-color: $red;
    border-radius: $radius;
    padding: $code-padding;
  }

  code,
  var {
    font-family: $family-code;
    font-size: $code-size;
    font-weight: $code-weight;
    color: $code;
    background-color: $code-background;
    padding: $code-padding;
    word-break: break-all;
  }

  blockquote {
    font-style: italic;
  }
}
