.h-is-clipped-touch {
  @include touch {
    overflow: hidden !important;
  }
}

.h-is-clickable {
  pointer-events: auto !important;
  cursor: pointer !important;
}

.h-is-padding-bottomless {
  padding-bottom: 0 !important;
}
