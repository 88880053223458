body {
  overflow-x: hidden;
}

.container {
  max-width: 1115px;
}

article {
  min-height: calc(100vh - 4.3rem - 9rem);
}
