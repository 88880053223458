.content pre code,
pre code,
.content pre,
pre {
  font-weight: 400;
  //font-size: 0.8125rem;
  color: #002d7a; //$body-color;
  line-height: 1.25rem; //1.1875rem;
  //white-space: pre-wrap;

  @include h-scrollbars;

  @include touch {
    font-size: 12px;
    line-height: 1.125rem;
  }
}

.hljs {
  display: block;
  overflow-x: auto;
}

/*.hljs-comment,
.hljs-quote {
  color: #d81b60; //#17a81a;//#616161; //#888a85; //#90a1b5 //#d81b60
  font-style: italic;
}

.hljs-section {
  color: #474747;
  font-weight: normal;
}

.hljs-link,
.hljs-params,
.hljs-formula,
.hljs-bullet,
.hljs-tag,
.hljs-built_in,
.hljs-subst {
  color: #474747;
}

.hljs-keyword,
.hljs-meta-keyword,
.hljs-doctag,
.hljs-regexp,
.hljs-meta-string {
  color: #3b78e7;//#18a2c1; //#05ad97; //#e96900 //#4ABF60 //#3b78e7
}

.hljs-string {
  color: #0d904f;//#55A1FB; //#18a2c1 //#0d904f
}

.hljs-variable {
  color: #4abf60;
}

.hljs-name,
.hljs-attribute,
.hljs-selector-tag {
  color: #3193c6;
}

.hljs-code,
.hljs-meta,
.hljs-symbol {
  color: #1990b8;
}

.hljs-number {
  color: #d35400;
}

.hljs-literal {
  color: #ed2b38;
}

.hljs-function {
  color: #bd0747;
}

.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-type,
.hljs-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #a15ebb;
}

.hljs-title {
  color: #9914c9;
}

.hljs-deletion {
  background: #ffdddd;
}

.hljs-addition {
  background: #ddffdd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: normal;
}*/
@at-root .hljs-comment,
  .hljs-quote {
  color: #abb8c6;
}

.hljs-variable,
.hljs-template-variable,
.hljs-regexp,
.hljs-deletion,
.hljs-keyword,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-attr {
  color: #e38800;
}

.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: #8d44eb;
}

.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-attr {
  color: #e77600;
}

.hljs-attribute {
  color: #108de8;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #2aab51;
}

.hljs-subst,
.hljs-number {
  color: #ed6e55;
}

.hljs-title,
.hljs-section,
.hljs-name {
  color: #fa3d58;
}

.hljs {
  display: block;
  overflow-x: auto;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
