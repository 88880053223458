.navbar {
  @include desktop {
    #h-search-form {
      @include h-animation;
      transition-property: transform, opacity;
      @include h-overlay;
      z-index: $navbar-fixed-z + 1;
      transform: translateY(-100%);
      opacity: 0;
      margin-right: -0.75rem;
    }

    .has-visible-search-from {
      .navbar-item {
        &:not(:first-child) {
          opacity: 0;
        }
      }

      #h-search-form {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .control {
      width: 100%;

      .input {
        border: 0;
        font-size: $h-navbar-font-size;
        color: $navbar-item-color;

        &::placeholder {
          color: $h-navbar-icon-color;
          opacity: 1;
        }

        &::-ms-input-placeholder {
          color: $h-navbar-icon-color;
        }
      }

      .icon {
        height: 2rem;
        color: $h-navbar-icon-color;
      }
    }

    .navbar-end {
      position: relative;
    }

    .navbar-item {
      @include h-animation(opacity);
    }
  }

  .control {
    .icon {
      color: $h-navbar-icon-color;
    }
  }
}
