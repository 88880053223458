.navbar {
  font-size: $h-navbar-font-size;
  @include h-animation(transform);

  &.h-is-hidden {
    transform: translateY(-100%);
  }

  #h-navbar-bottom {
    position: absolute;
    color: transparent;
    background-color: transparent;
    height: 0.5rem;
    left: 0;
    right: 0;
    top: 100%;
  }

  #h-logo {
    font-size: $h-navbar-logo-font-size;
    line-height: 0;
    color: $text-strong;
    font-weight: 500;

    &:hover {
      color: $text-strong;
    }

    img {
      padding-right: 0.4375rem;
      height: $navbar-item-img-max-height;
    }

    .is-sub {
      padding-left: 0.3125rem;
      font-weight: 400;
    }
  }

  .navbar-item,
  .navbar-link {
    .icon {
      &:only-child {
        margin-right: -0.125rem;
      }

      img {
        height: 0.875rem;
      }
    }
  }

  @include desktop {
    .has-dropdown {
      .navbar-link {
        padding-right: 1.75rem;

        &:after {
          right: 0.8125rem;
        }
      }
    }

    .navbar-dropdown {
      .navbar-item {
        &:hover {
          background-color: $navbar-item-hover-background-color;
          color: $navbar-item-hover-color;
          transition: none;
        }
      }
    }
  }

  .navbar-link {
    &:after {
      border-width: 2px;
      border-color: $h-navbar-icon-color;
      width: 0.375rem;
      height: 0.375rem;
    }
  }

  .icon {
    color: $h-navbar-icon-color;
  }

  .navbar-burger {
    color: $h-navbar-icon-color;
  }

  .is-github-icon {
    color: $h-github;
  }

  .navbar-burger {
    span {
      height: 2px;
      width: 18px;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
