@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

$h-teal: #18a2c1;
$h-boston-blue: #2e86b7;//#2e8db2;
$h-github: #24292e;
$h-teal-invert: findColorInvert($h-teal);
$h-boston-blue-invert: findColorInvert($h-boston-blue);
$h-github-invert: findColorInvert($h-github);

$h-speed-normal: 0.5s;
$h-navbar-font-size: 0.875rem;
$h-navbar-logo-font-size: 1.75rem;
$h-navbar-icon-color: $grey;
$h-hr-special-background-color: $h-boston-blue;
$h-h1-font-size: 1.75rem;
$h-h2-font-size: 1.625rem;
$h-h3-font-size: 1.5rem;
$h-h4-font-size: 1.375rem;
$h-h5-font-size: 1.25rem;
$h-h6-font-size: 1.125rem;
$h-gallery-gap: 10px;

$red: #de2b26;
$green: #4abf60;
$white-ter: #f8f8f8;
$primary: $h-boston-blue;
$link: $h-boston-blue;
$link-hover: lighten($link, 5%);

$family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;
$family-monospace: "Roboto Mono", monospace;
$easing: ease-in-out;

$content-heading-weight: 400;
$navbar-dropdown-boxed-shadow: none;
$navbar-item-hover-background-color: transparent;
$navbar-height: 3rem;
$navbar-item-img-max-height: 2rem;
$section-padding: 1.5rem;
$input-shadow: none;
$subtitle-size: 1rem;
$subtitle-line-height: 1.5;
$code-weight: 500;
$code-padding: 0.1875rem 0.4rem;
$code-size: 0.875rem;
$content-pre-padding: 6px;

@import "~bulma/sass/utilities/derived-variables";

$addColors: (
  "teal": (
    $h-boston-blue,
    $h-boston-blue-invert
  ),
  "github": (
    $h-github,
    $h-github-invert
  )
);
$colors: map-merge($colors, $addColors);

@import "~bulma/bulma";
@import "~lightgallery.js/src/sass/lightgallery";

@import "fonts";
@import "mixins";
@import "helpers";
@import "common";
@import "header";
@import "search-panel";
@import "sections";
@import "form";
@import "images";
@import "content";
@import "highlight";
