@mixin h-animation($property: all) {
  transition: $property $h-speed-normal $easing;
}

@mixin h-clickable {
  pointer-events: auto !important;
  cursor: pointer !important;
}

@mixin h-fullwidth {
  width: 100%;
}

@mixin h-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

@mixin h-scrollbars {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: $radius-large;
  }

  &::-webkit-scrollbar:horizontal {
    height: $radius-large;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-light;
    border-radius: $radius-small;
  }
}

@mixin h-fix-margin($margin: 0) {
  margin-left: $margin;
  margin-right: $margin;

  &:not(:first-child) {
    margin-top: $margin;
  }

  &:not(:last-child) {
    margin-bottom: $margin;
  }
}
