.h-lightbox {
  img {
    max-width: 100%;
    height: auto;
    cursor: zoom-in;
  }
}

.h-gallery {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  -webkit-align-items: stretch;
  -webkit-justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start; //space-around;
  width: calc(100% + #{$h-gallery-gap});

  img {
    display: block;
    width: auto;
    height: 200px;
  }

  div {
    display: -webkit-flex;
    -webkit-align-items: flex-end;
    -webkit-justify-content: space-around;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-right: $h-gallery-gap;
    margin-bottom: $h-gallery-gap;
    width: auto;
    height: auto;

    cursor: zoom-in;
  }

  &:after {
    -ms-flex: 20 150px;
    -webkit-flex: 20 150px;
    flex: 20 150px;
    display: block;
    content: " ";
    height: 1px;
  }

  @include widescreen {
    &:after {
      -ms-flex: 500 300px;
      -webkit-flex: 500 300px;
      flex: 500 300px;
    }
  }

  @include desktop {
    &:after {
      -ms-flex: 100 250px;
      -webkit-flex: 100 250px;
      flex: 100 250px;
    }
  }

  @include tablet {
    &:after {
      -ms-flex: 50 200px;
      -webkit-flex: 50 200px;
      flex: 50 200px;
    }
  }
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  border-radius: 0;
  border-width: 0;

  &:hover {
    opacity: 0.8;
  }
}

.content figure {
  @include h-fix-margin;

  &.is-pulled-left {
    @include h-fix-margin(0);
    margin-right: 1rem;
  }

  &.is-pulled-right {
    @include h-fix-margin(0);
    margin-left: 1rem;
  }
}
