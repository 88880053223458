@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Roboto-Italic.ttf);
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/RobotoMono-Regular.ttf);
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/RobotoMono-Medium.ttf);
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/RobotoMono-Bold.ttf);
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/RobotoMono-Italic.ttf);
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/RobotoMono-MediumItalic.ttf);
}
