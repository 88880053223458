.section {
  .h-has-special-hr {
    margin-bottom: 2.5rem;

    &:after {
      background: $h-hr-special-background-color;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      right: 0;
      margin: 1.25rem auto;
      position: relative;
      width: 3rem;
    }
  }

  @include touch {
    padding: 0.9375rem;
  }
}
